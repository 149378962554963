import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { ContactForm } from "@/components/ContactForm";
import { SuccessPageContent } from "@/components/SuccessPageContent";
import { FailurePageContent } from "@/components/FailurePageContent";
import { BusinessRegistryEntries } from "@/components/BusinessRegistryEntries";
import { ContactInformation } from "@/components/ContactInformation";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import { useFormHandler } from "@/hooks/useFormHandler";

const Contact: FC<PageProps<GatsbyTypes.Query>> = ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    metaKeywords,
  } = data.contentfulContactPage;
  const {
    state,
    errors,
    isValid,
    isRecaptcha,
    register,
    handleCaptchaChange,
    handleFormSubmit,
    handleMailingListChange,
  } = useFormHandler(process.env.FORMCARRY_CONTACT_FORM_ID);

  return (
    <MainLayout>
      <Helmet>
        <body className="ltr contact-page" id="intro" />
      </Helmet>
      <SEO
        title={metaTitle}
        description={metaDescription.metaDescription}
        keywords={metaKeywords}
      />
      {state.submitted && (
        <SuccessPageContent
          heading="Thank you for contacting ANIXE!"
          text="Your message has been sent. We’ll get back to you soon."
        />
      )}
      {state.error && (
        <FailurePageContent goBackUrl="/contact" goBackText="return" />
      )}
      {!state.submitted && !state.error && (
        <>
          <section className="hero-section color-bg-light l-full-mobile">
            <div className="l-wrapper clearfix">
              <div className="l-8-cols-tablet l-9-cols-desktop">
                <h1 className="color-primary">Contact Us</h1>
                <p className="hero-image-content-body l-push-bottom">
                  Want to know more about our services? Are you interested in
                  cooperation?
                  <br /> Please leave us a message using specialized forms
                  beside or the general one below.
                  <br /> We will respond to every single note we receive.
                  <br />
                  <br /> Lets get in touch! We will be happy to help.
                </p>
              </div>
              <div className="l-4-cols-tablet l-3-cols-desktop l-with-dbl-gutter-top-mobile">
                <a
                  className="link-button button button-ghost-quaternary l-full-tablet l-push-bottom"
                  href="/estimate-project"
                >
                  estimate project
                </a>
                <a
                  className="link-button button button-ghost-primary l-full-tablet"
                  href="/demo-form"
                >
                  request a demo
                </a>
              </div>
            </div>
          </section>
          <ContactForm
            disabled={!isRecaptcha || !isValid}
            errors={errors}
            register={register}
            handleCaptchaChange={handleCaptchaChange}
            handleFormSubmit={handleFormSubmit}
            handleMailingListChange={handleMailingListChange}
          />
          <ContactInformation />
          <BusinessRegistryEntries />
        </>
      )}
      <SiteFooterWrapper>
        <SiteFooterNewsletterButton />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default Contact;
export const query = graphql`
  {
    contentfulContactPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords
    }
  }
`;
