import React from "react";
import { GoogleMap } from "@/components/GoogleMap";
import { SocialMediaItem } from "@/components/basic/SocialMediaItem";
import { mapLocationPoland } from "@/utils/googleMapCompanyLocations";
import { contactPoland } from "@/utils/companyContactData";
import { socialsPoland } from "@/utils/companySocialMedia";

export const ContactInformation = () => (
  <section className="color-bg-light">
    <div className="l-wrapper clearfix l-with-dbl-vertical-gutters-mobile l-dbl-push-bottom ">
      <div className="clearfix l-with-dbl-vertical-gutters-mobile">
        <div className="l-6-cols-tablet l-island-mobile l-push-bottom clearfix">
          <div className="clearfix">
            <h2 className="color-primary">ANIXE Polska</h2>
            <div className="l-6-cols-mobile l-bleed">
              <strong>Address</strong>
              <address>
                {contactPoland.name} <br />
                ul. {contactPoland.streetAndNumber} <br />
                {contactPoland.postcodeAndCity}
                <br />
                {contactPoland.country} <br />
                <a href={contactPoland.phoneSales}>
                  {contactPoland.phoneSales}
                </a>
                <br />
                {contactPoland.fax} <br />
              </address>
            </div>
            <div className="l-6-cols-mobile l-bleed">
              <strong className="d-block">Sales</strong>
              <a href={contactPoland.phoneSales}>{contactPoland.phoneSales}</a>
              <strong className="d-block">Support</strong>
              <a href={contactPoland.phoneSupport}>
                {contactPoland.phoneSupport}
              </a>
            </div>
          </div>
          <ul className="l-push-top l-push-bottom d-flex-box">
            <SocialMediaItem
              alt="facebook"
              url={socialsPoland.facebook}
              iconUrl="/images/social/fb_gray.svg"
              iconWidth="36"
              iconHeight="36"
            />
            <SocialMediaItem
              classname="l-with-gutter-before-mobile"
              alt="twitter"
              url={socialsPoland.twitter}
              iconUrl="/images/social/twitter_gray.svg"
              iconWidth="36"
              iconHeight="36"
            />
            <SocialMediaItem
              classname="l-with-gutter-before-mobile"
              alt="linkedin"
              url={socialsPoland.linkedin}
              iconUrl="/images/social/linkedin_gray.svg"
              iconWidth="36"
              iconHeight="36"
            />
          </ul>
          <GoogleMap url={mapLocationPoland} />
        </div>
      </div>
    </div>
  </section>
);
