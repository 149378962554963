import React, { FC, FormEventHandler, Ref } from "react";
import { Input } from "components/basic/Input";
import { ValidatedInput } from "components/basic/ValidatedInput";
import { Textarea } from "@/components/basic/Textarea";
import ReCAPTCHA from "react-google-recaptcha";
import {
  requiredFieldMessage as required,
  invalidFormatMessage as invalid,
} from "@/utils/validationMessages";
import { emailRegExp } from "@/utils/validation";
import { FormSubmitButton } from "@/components/basic/FormSubmitButton";
import { PreviousPageInput } from "@/components/basic/PreviousPageInput";
import { MailingListCheckbox } from "@/components/MailingListCheckbox";
import { PrivacyPolicyInfo } from "./PrivacyPolicyInfo";

export interface Error {
  type: string;
}
export interface Errors {
  email?: Error;
  name?: string;
  message?: string;
}
export interface ContactFormProps {
  handleFormSubmit: FormEventHandler<HTMLFormElement>;
  disabled: boolean;
  handleMailingListChange: () => void;
  handleCaptchaChange: () => void;
  errors: Errors;
  register?: (ref: string, { required: boolean, pattern: RegExp }) => void;
}

export const ContactForm: FC<ContactFormProps> = ({
  errors,
  disabled,
  register,
  handleFormSubmit,
  handleMailingListChange,
  handleCaptchaChange,
}) => (
  <div className="l-wrapper color-bg-negative clearfix l-with-dbl-vertical-gutters-mobile l-dbl-push-bottom">
    <div className="l-island l-10-cols-tablet l-6-cols-desktop l-dbl-push-bottom centered-text-section centered-text-section-secondary">
      <h2 className="color-primary align-center">Leave us a message</h2>
      <p className="align-center">
        If you need more information on our products, solutions or have any
        other inquiries, please fill in the form below.
      </p>
      <p className="l-push-bottom">
        <strong className="color-error">*</strong>
        required fields
      </p>
    </div>
    <div className="l-10-cols-tablet l-island l-dbl-push-bottom">
      <form className="default-form" onSubmit={handleFormSubmit}>
        <div className="l-half-push-bottom l-bleed clearfix">
          <div className="l-half-tablet">
            <ValidatedInput
              name="name"
              label="Name"
              register={register}
              errorMessage={errors.name && required}
            />
          </div>
          <div className="l-half-tablet">
            <Input name="companyName" label="Company name" />
          </div>
        </div>
        <div className="l-half-push-bottom l-bleed clearfix">
          <div className="l-half-tablet">
            <ValidatedInput
              name="email"
              type="email"
              label="Email address"
              register={register}
              pattern={emailRegExp}
              errorMessage={
                errors.email &&
                (errors.email.type === "pattern" ? invalid : required)
              }
            />
          </div>
          <div className="l-half-tablet">
            <Input name="phone" label="Phone number" type="tel" />
          </div>
        </div>
        <div className="l-full-mobile l-push-bottom">
          <Textarea
            label="Your Message"
            name="message"
            register={register}
            errorMessage={errors.message && required}
          />
        </div>
        <PrivacyPolicyInfo />
        <div className="l-full-mobile l-dbl-push-bottom text-12">
          <MailingListCheckbox handleChange={handleMailingListChange} />
        </div>
        <PreviousPageInput />
        <div className="align-center clearfix">
          <div className="l-half-tablet">
            <ReCAPTCHA
              sitekey={process.env.FORMCARRY_SITEKEY}
              onChange={handleCaptchaChange}
            />
          </div>
          <FormSubmitButton text="agree &amp; send" disabled={disabled} />
        </div>
      </form>
    </div>
  </div>
);
