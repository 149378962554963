import React from "react";

export const BusinessRegistryEntries = () => (
  <div className="l-wrapper clearfix l-with-dbl-gutter-bottom-mobile l-dbl-push-bottom">
    <div className="clearfix">
      <div className="l-12-cols-tablet l-island">
        <div className="l-push-bottom">
          <h2 className="color-primary">Business Registry Entry</h2>
        </div>
        <div className="clearfix">
          <div className="l-full-mobile l-half-tablet l-bleed-before">
            <p className="l-half-push-bottom l-bleed">
              <strong>ANIXE Polska sp. z o.o.</strong>
            </p>
            <p className="l-push-bottom l-bleed">
              Seated in Wroclaw, entered into the Register of Entrepreneurs of
              the National Court Register maintained by the District Court for
              Wrocław - Fabryczna, the 6th Commercial Division of the National
              Court Register under number 0000008486, with Tax Identification
              Number (NIP): 8992409480, share capital: PLN 105,000.00
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
