import React, { FC } from "react";

export interface GoogleMapProps {
  url: string;
}

export const GoogleMap: FC<GoogleMapProps> = ({ url }) => (
  <div className="map-container l-push-top">
    <iframe width="100%" height="435" src={url}></iframe>
  </div>
);
